import React from "react";
import { makeStyles } from "@mui/styles";
import { Drawer, Box, Typography, Button } from "@mui/material";

const useStyles = makeStyles(() => ({
  drawerPaper: {
    borderRadius: "8px 8px 0px 0px",
    boxShadow: "none",
  },
  drawerContent: {
    background: "#3D0046",
    padding: "8px 0px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    color: "#FFFFFF",
    padding: "2px 0px",
  },
  divider: {
    width: "100%",
    backgroundColor: "#767676",
    height: "1px",
    border: "none",
  },
  buttonContainer: {
    marginTop: "8px",
    width: "100%",
    padding: "8px 16px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  actionButton: {
    padding: "8px 0px !important",
    textAlign: "center",
    width: "100%",
    fontWeight: "bold !important",
    background: "linear-gradient(to right, #C300C3, #9C00FF)",
    color: "#FFFFFF !important",
    borderRadius: "10px !important",
    boxShadow: "none",
    fontSize: "18px !important",
    textTransform: "capitalize",
  },
  closeButton: {
    fontSize: "16px",
    color: "#FFFFFF !important",
    fontWeight: "bold !important",
    marginTop: "8px",
    textTransform: "capitalize",
  },
}));

function InviteDrawer({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const classes = useStyles();
  const handleShare = () => {
    const miniAppUrl = "https://t.me/unicastjobs";
    const telegramShareUrl = `https://t.me/share/url?text=${encodeURIComponent(
      `Hey 👋\nJoin me on UnicastAI channel and check its mini-app to find top Web3 jobs!\nClick to join: ${miniAppUrl}`
    )}`;

    window.open(telegramShareUrl, "_blank");
  };

  const [copied, setCopied] = React.useState<boolean>(false);

  const handleCopy = async () => {
    const miniAppUrl = "https://t.me/unicastjobs";
    await navigator.clipboard.writeText(miniAppUrl);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        className: classes.drawerPaper,
      }}
    >
      <Box className={classes.drawerContent}>
        <Typography variant="h6" className={classes.title}>
          Invite a friend
        </Typography>

        <hr className={classes.divider} />

        <Box className={classes.buttonContainer}>
          <Button
            className={classes.actionButton}
            onClick={handleShare}
            sx={{ textTransform: "none" }}
          >
            Send
          </Button>
          <Button
            className={classes.actionButton}
            onClick={handleCopy}
            sx={{ textTransform: "none" }}
          >
            {!copied ? "Copy link" : "Copied!"}
          </Button>
        </Box>

        <Button
          onClick={onClose}
          className={classes.closeButton}
          sx={{ textTransform: "none" }}
        >
          Close
        </Button>
      </Box>
    </Drawer>
  );
}

export default InviteDrawer;
