import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { useTelegram } from "../../utils/Telegram.Provider";
import InviteDrawer from "./components/InviteDrawer";
import FriendsOverview from "./components/FriendsOverview";
import { useGetFriends } from "../../api/hooks/friends/useGetFriends";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

const useStyles = makeStyles(() => ({
  pageTitle: {
    fontWeight: "bold !important",
    fontSize: "24px !important",
    fontFamily: "Helvetica Neue !important",
    padding: "0px",
    margin: "0px",
  },
  banner: {
    textAlign: "center",
    marginTop: "24px",
    background: "linear-gradient(to right, #000000, #4F0069)",
    borderRadius: "16px",
    padding: "16px 32px",
    marginLeft: "16px",
    marginRight: "16px",
  },
  imageBannerContainer: {
    marginTop: "24px",
    marginLeft: "16px",
    marginRight: "16px",
  },
  instructions: {
    width: "100%",
    margin: "0 auto",
    borderRadius: "8px",
  },
  buttonContainer: {
    textAlign: "center",
    width: "100%",
    position: "fixed",
    bottom: "14%",
  },
  button: {
    padding: "8px 0px !important",
    textAlign: "center",
    width: "92%",
    background: "linear-gradient(to right, #C300C3, #9C00FF)",
    color: "#FFFFFF !important",
    borderRadius: "10px !important",
    boxShadow: "none",
    fontSize: "18px !important",
    fontWeight: "bold !important",
  },
}));

function FriendsPage() {
  const classes = useStyles();

  const webApp = useTelegram();
  const navigate = useNavigate();

  const onBackClick = React.useCallback(() => {
    navigate("/");
  }, [navigate]);

  React.useEffect(() => {
    if (webApp) {
      webApp.BackButton.onClick(onBackClick);
      webApp.BackButton.show();
    }
  }, [webApp, onBackClick]);

  // To be fetched from backend
  const { data, isLoading } = useGetFriends(
    webApp?.initDataUnsafe?.user?.id || 1
  );
  const [open, setOpen] = React.useState(false);

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      <Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Box className={classes.banner}>
            <Typography className={classes.pageTitle}>
              Invite friends,
            </Typography>
            <Typography className={classes.pageTitle}>
              get rewards ✨
            </Typography>
          </Box>

          {!data?.friends || data.friends.length === 0 ? (
            <Box>
              <Box className={classes.imageBannerContainer}>
                <img
                  className={classes.instructions}
                  src={"/assets/images/rewards.png"}
                  alt="rewards-instructions"
                />
              </Box>
              <Box className={classes.banner}>
                <Typography variant="body1">
                  Stay tuned, we will be sharing more details about rewards very
                  soon! 🔥
                </Typography>
              </Box>
            </Box>
          ) : (
            <FriendsOverview
              friends={data?.friends || []}
              totalPoints={data?.points || 0}
            />
          )}
        </div>
      </Box>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          sx={{ textTransform: "none" }}
          onClick={() => setOpen(true)}
        >
          Invite a friend 🔗
        </Button>
        <InviteDrawer open={open} onClose={() => setOpen(false)} />
      </div>
    </>
  );
}

export default FriendsPage;
